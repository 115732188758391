import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { ITpa, ITpaComponentApi, TpaPageConfig } from './types'
import { name, TpaComponentApiSymbol } from './symbols'
import { IPropsStore, PageFeatureConfigSymbol, Props, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'

export const TpaDs = withDependencies(
	[
		Props,
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		TpaComponentApiSymbol,
	],
	(
		props: IPropsStore,
		{ widgetsClientSpecMapData }: TpaCommonsSiteConfig,
		{ widgets, tpaInnerRouteConfig, pageId }: TpaPageConfig,
		tpaComponentApi: ITpaComponentApi
	): ITpa => {
		const rebuildTpaSrc = (compId: string) => {
			const src = tpaComponentApi.buildSrc({
				compId,
				tpaCompData: widgets[compId]!,
				pageId,
				tpaInnerRouteConfig,
			})
			props.update({
				[compId]: {
					src,
				},
			})
		}

		const rebuildTpasSrc = () => {
			const tpas = _.pickBy(widgets, ({ widgetId, isOOI }) => !isOOI && widgetsClientSpecMapData[widgetId])
			Object.keys(tpas).forEach(rebuildTpaSrc)
		}

		return {
			rebuildTpasSrc,
		}
	}
)
